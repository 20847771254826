<template>
  <div>
    <AuBreadcrumb
      parent-name="Profile"
      parent-path="user"
      current-page-title="Wallet verification"
    />
    <div class="card">
      <div
        v-if="!checkVerifySuccess"
        class="title"
      >
        {{ $t("walletVerification.title_1") }}
      </div>
      <div
        v-if="checkVerifySuccess"
        class="title"
      >
        {{ $t("walletVerification.title_2") }}
      </div>
      <div class="alert-block">
        <!-- Алерты -->
        <div
          v-if="(accountAddress && arrEth[0]?.value && (accountAddress != arrEth[0]?.value)) && !ethExists"
          class="alert alert-secondary"
          role="alert"
        >
          <div>
            {{ $t("walletVerification.alert_1") }}
          </div>
          <div>
            {{ $t("walletVerification.alert_2") }} {{ arrEth[0]?.value }}
          </div>
          <div>
            {{ $t("walletVerification.alert_3") }} {{ accountAddress }}
          </div>
        </div>
        <div
          v-if="accountAddress && ethExists"
          class="alert alert-danger"
          role="alert"
        >
          <div>
            {{ $t("walletVerification.alert_4") }} {{ accountAddress }}
          </div>
        </div>
      </div>
      <!-- Не установлен MetaMask -->
      <div
        v-if="!mmInstalled"
        class="subtitle"
      >
        <div>
          {{ $t("walletVerification.subtitle_1") }}
        </div>
        <div
          class="mt-4"
        >
          {{ $t("walletVerification.subtitle_2") }}
        </div>

        <a
          class="button-link"
          href="https://metamask.io/download"
          target="_blank"
        >
          <AuButton
            v-if="!mmInstalled"
            class="mt-2"
            type="primary"
            center
            width="200px"
          >
            <div class="btn_metamask">
              <div
                class="metamask"
              />
              <div>
                {{ $t("walletVerification.btn_1") }}
              </div>
            </div>
          </AuButton>
        </a>
      </div>

      <!-- Установлен MetaMask -->
      <div
        v-if="mmInstalled"
        class="subtitle"
      >
        <!-- Установлен - Не подключен -->
        <div
          v-if="!accountAddress && !checkVerifySuccess"
          class="subtitle"
        >
          <div>
            {{ $t("walletVerification.subtitle_3") }}
          </div>
          <div
            v-if="!statusConnection"
            class="mt-4"
          >
            {{ $t("walletVerification.subtitle_4") }}
          </div>
          <div
            v-if="statusConnection"
            class="mt-4"
          >
            {{ $t("walletVerification.subtitle_5") }}
          </div>
          <div
            v-if="statusConnection"
          >
            {{ $t("walletVerification.subtitleNote") }}
          </div>
          <AuButton
            :disabled="statusConnection"
            class="mt-2"
            type="primary"
            center
            width="200px"
            @click="connectMetamaskAction()"
          >
            <div class="btn_metamask">
              <div
                class="metamask"
              />
              <div>
                {{ $t('walletVerification.btn_2') }}
              </div>
            </div>
          </AuButton>
        </div>

        <!-- Установлен - Подключен - Сохранен Кошелек в БД - НЕ совпадают -->
        <div
          v-if="checkChange"
          class="subtitle"
        >
          <div>
            {{ $t("walletVerification.subtitle_7_1") }} {{ accountAddress }} {{ $t("walletVerification.subtitle_7_2") }}
          </div>
          <div
            class="mt-4"
          >
            {{ $t("walletVerification.subtitle_8") }}
          </div>
          <div
            class="mt-2"
          >
            {{ $t("walletVerification.subtitle_9") }}
          </div>
          <div>
            {{ $t("walletVerification.subtitleNote") }}
          </div>
          <AuButton
            :disabled="statusChange"
            class="mt-2"
            type="primary"
            center
            width="200px"
            @click="openMetamaskAction()"
          >
            <div class="btn_metamask">
              <div class="metamask" />
              <div>
                {{ $t('walletVerification.btn_3') }}
              </div>
            </div>
          </AuButton>
        </div>

        <!-- Установлен - Подключен - Сохранен Кошелек в БД - Совпадают  -->
        <div
          v-if="!checkChange && accountAddress && !arrEth[0]?.status"
          class="subtitle"
        >
          <div>
            {{ $t("walletVerification.subtitle_11_1") }} {{ accountAddress }} {{ $t("walletVerification.subtitle_11_2") }}
          </div>
          <div
            v-if="!satusSign"
            class="mt-4"
          >
            {{ $t("walletVerification.subtitle_12") }}
          </div>
          <div
            v-if="satusSign"
            class="mt-4"
          >
            {{ $t("walletVerification.subtitle_13") }}
          </div>
          <div
            v-if="satusSign"
          >
            {{ $t("walletVerification.subtitleNote") }}
          </div>
          <AuButton
            :disabled="satusSign"
            class="mt-2"
            type="primary"
            center
            width="200px"
            @click="startVerify"
          >
            <div class="btn_metamask">
              <div
                class="metamask"
              />
              <div>
                {{ $t('walletVerification.btn_4') }}
              </div>
            </div>
          </AuButton>
        </div>

        <!-- Верифицирован -->
        <div v-if="checkVerifySuccess">
          <div class="subtitle">
            {{ $t("walletVerification.subtitle_14") }}
          </div>
          <div class="subtitle">
            {{ $t("walletVerification.subtitle_15") }}
          </div>
          <div class="d-flex align-items-center mt-4">
            <AuButton
              center
              width="200px"
              @click="gotoPage('buy form')"
            >
              {{ $t("walletVerification.btn_6") }}
            </AuButton>
            <AuButton
              class="ms-2"
              center
              type="primary"
              width="200px"
              @click="gotoCreate"
            >
              {{ companyCount > 0 ?$t("walletVerification.btn_5") : $t("walletVerification.btn_7") }}
            </AuButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuButton from "@/components/extended/AuButton.vue";
import MetaMask from "@/mixins/MetaMask";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Verification",
  components: {
    AuButton
  },

  mixins: [MetaMask],

  data() {
    return {
      ethExists: false,
      satusSign: false
    };
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses",
      accountAddress: "accountAddress",
      companyCount: "companyCount"
    }),

    checkVerifySuccess() {
      return this.arrEth[0]?.value && this.arrEth[0]?.status && this.arrEth[0]?.verifiedAt;
    },

    checkChange() {
      return (this.accountAddress && this.arrEth[0]?.value && (this.accountAddress != this.arrEth[0]?.value)) || this.ethExists;
    }
  },

  watch: {
    accountAddress() {
      this.ethExists = false;
      this.btnSignIn = true;
    }
  },

  methods: {
    ...mapActions({
      postEthAddresses: "postEthAddresses",
      getEthAddresses: "getEthAddresses",
      getValidateContent: "getValidateContent",
      signAndVerify: "signAndVerify",
      patchEthAddresses: "patchEthAddresses"
    }),

    gotoPage(url) {
      this.$router.push({ name: url });
    },
    gotoCreate() {
      if (this.companyCount > 0) {
        this.$router.push("/my-projects/edit-project/new");
      }
      else {
        this.$router.push("/companies/company-manager");
      }
    },

    sendCarrot(address) {
      if (window.carrotquest && process.env.VUE_APP_CARROT_ENABLED === "true") {
        window.carrotquest.track("metamask assigned", {
          "address": address
        });
        window.carrotquest.identify([
          { op: "update_or_create", key: "metaMaskWallet", value: address }
        ]);
      }
    },

    async addEth() {
      const id = this.$auth.user().id;
      try {
        const resp = await this.postEthAddresses({
          value: this.accountAddress.toLowerCase(),
          user: `/api/users/${id}`
        });
        if (resp?.data.message == "Address already exists!") {
          this.$toast.warning(resp?.data.message);
          this.ethExists = true;
          this.satusSign = false;
        }
        else {
          await this.getEthAddresses();
          this.ethExists = false;
          this.validateAddress();
        }
      }
      catch (error) {
        console.log("Error_addEth", error);
        this.satusSign = false;
        await this.getEthAddresses();
        this.$toast.error(error?.message);
      }
    },

    startVerify() {
      this.satusSign = true;
      if (this.arrEth[0]?.value && (this.accountAddress == this.arrEth[0]?.value)) {
        this.validateAddress();
      }
      else {
        this.addEth();
      }
    },

    async validateAddress() {
      const currentAddress = this.arrEth[0]?.value.toLowerCase();
      try {
        this.data = await this.getValidateContent(currentAddress);
        const dataToSign = this.data.content;

        const sign = await this.web3.eth.personal.sign(
          dataToSign,
          currentAddress,
          ""
        );

        let data = await this.signAndVerify({ accountAddress: currentAddress, sign: sign });

        if (data.result) {
          await this.addToRegistry(this.arrEth[0]?.id);
          this.sendCarrot(this.accountAddress.toLowerCase());
        }
        else {
          console.log("Error_result", data);
          this.satusSign = false;
          await this.getEthAddresses();
          this.$toast.error(this.$t("validate.error"));
        }
      }
      catch (error) {
        console.log("Error_validateAddress", error);
        this.satusSign = false;
        await this.getEthAddresses();
        this.$toast.error(error?.message);
      }
    },

    async addToRegistry(id) {
      let body = {
        status: "add"
      };

      try {
        await this.patchEthAddresses({ id: id, body: body });
        await this.getEthAddresses();
        this.satusSign = false;
        this.$toast.success(this.$t("validate.success"));
      }
      catch (error) {
        await this.getEthAddresses();
        this.satusSign = false;
        console.error("Error_addToRegistry", error);
      }
    }
  }

};
</script>

<style scoped lang="scss">

.card {
  padding: 20px;
  border-radius: 12px;
}

.title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 14px;
  // margin-bottom: 15px;
}

.btn_metamask {
  display: flex;
  justify-content: center;
  align-items: center;
}
.metamask {
  background: url(../assets/img/metamask_big.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 8px;
  margin-right: 10px;

}
.link {
  color: #ffffff;
}
.button-link {
  display: inline-block;
  text-decoration: none;
}
</style>