<template>
  <div class="is2 card">
    <div class="form-header">
      <div class="title">
        {{ `${mode} ${$t('projects.form.secondStep.labelLastPart')}` }}
      </div>
      <AuButton
        width="125px"
        center
        bordered
        @click="goBack"
      >
        {{ $t("projects.form.cancelButton") }}
      </AuButton>
    </div>

    <div class="d-flex is-indent select-date">
      <div class="d-flex flex-column is-line">
        <span class="is-label">{{ $t('projects.form.secondStep.projectDateStartLabel') }}</span>

        <AuInput
          :model-value="dateStart"
          :min-date="minDate"
          :format="formatView"
          :enable-minutes="false"
          type="Date"
          enable-time-picker
          required
          clearable
          :placeholder="$t('projects.form.secondStep.projectDateStartPlaceholder')"
          @update:modelValue="setDateStart"
        />
      </div>
      <div class="d-flex flex-column is-line">
        <span class="is-label">{{ $t('projects.form.secondStep.projectDateEndLabel') }}</span>

        <AuInput
          :model-value="dateEnd"
          :min-date="minDateAfterMinDate"
          :format="formatView"
          :enable-minutes="false"
          type="Date"
          enable-time-picker
          required
          clearable
          :placeholder="$t('projects.form.secondStep.projectDateEndPlaceholder')"
          @update:modelValue="setDateEnd"
        />
      </div>
    </div>

    <div
      v-if="!showPriceRedeem"
      class="is-label"
    >
      <div class="label-name">
        {{ $t('projects.form.secondStep.projectExpectedProfitLabel') }}<AuTooltip :description="' Expected value in percents which could be achieved in the year range.'" />
      </div>
    </div>
    <div
      v-if="!showPriceRedeem"
      class="is-indent"
    >
      <AuInput
        :model-value="expectedProfit"
        type="Mask"
        required
        clearable
        :placeholder="$t('projects.form.secondStep.projectExpectedProfitPlaceholder')"
        :mask="/^[1-9]+\d{0,4}$/"
        @update:modelValue="setExpectedProfit"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.secondStep.projectSectionLabel') }}
    </div>
    <div class="is-indent">
      <AuSelect
        :value="section"
        :options="capitalsSectionsOptions"
        required
        clearable
        :placeholder="$t('projects.form.secondStep.projectSectionPlaceholder')"
        @input="value => section = value"
      />
    </div>
    <div
      v-if="showPriceRedeem"
      class="is-label"
    >
      {{ $t('projects.form.secondStep.projectPriceRedeemLabel') }}
    </div>
    <div
      v-if="showPriceRedeem"
      class="is-indent"
    >
      <AuInput
        v-model="priceRedeem"
        required
        :placeholder="$t('projects.form.secondStep.projectPriceRedeemPlaceholder')"
        type="Mask"
        :mask="Number"
      />
    </div>
    <div
      v-if="showPriceRedeem"
      class="is-label"
    >
      {{ $t('projects.form.secondStep.projectDateCloseLabel') }}
    </div>
    <div
      v-if="showPriceRedeem"
      class="is-indent"
    >
      <AuInput
        :model-value="dateClose"
        :min-date="minDateAfterMinDate"
        :format="formatView"
        :enable-minutes="false"
        enable-time-picker
        type="Date"
        required
        clearable
        :placeholder="$t('projects.form.secondStep.projectDateClosePlaceholder')"
        @update:modelValue="setDateClose"
      />
    </div>
    <div
      class="is-indent is-advanced"
      @click="showAdvanced=!showAdvanced"
    >
      <span>
        {{ $t("projects.form.secondStep.projectAdvanced") }}
      </span>
      <AuIcon
        :icon="showAdvanced ? 'chevron_up' : 'chevron_down'"
        clickable
        @click="showAdvanced=!showAdvanced"
      />
    </div>
    <div v-show="showAdvanced">
      <div class="is-label">
        {{ $t('projects.form.secondStep.projectPriceBaseLabel') }} {{ priceInMnemonic }}
      </div>
      <div class="is-small-indent">
        <AuInput
          v-model="priceBase"
          required
          clearable
          :placeholder="$t('projects.form.secondStep.projectPriceBasePlaceholder')"
          type="Number"
          :decimal="currentTokenDecimals"
        />
      </div>
      <div class="is-label">
        {{ $t('projects.form.secondStep.projectMinNumLabel') }}
      </div>
      <div class="is-indent">
        <AuInput
          v-model="minCol"
          :placeholder="$t('projects.form.secondStep.projectMinNumPlaceholder')"
          type="Mask"
          :mask="Number"
          :max="tokensCount"
        />
      </div>
      <div class="d-flex is-indent select-cap">
        <div class="is-line is-minmax">
          <span class="is-text-inside">{{ $t('projects.form.secondStep.projectMinPriceLabel') }}</span>
          <AuDecimalStatic
            :value="minCap"
            :addition="priceInMnemonic"
            :show-addition="!!priceInMnemonic"
            :decimal="currentTokenDecimals"
          />
        </div>
        <div class="is-line is-minmax">
          <span class="is-text-inside">{{ $t('projects.form.secondStep.projectMaxPriceLabel') }}</span>
          <AuDecimalStatic
            :value="maxCap"
            :addition="priceInMnemonic"
            :show-addition="!!priceInMnemonic"
            :decimal="currentTokenDecimals"
          />
        </div>
      </div>
      <div class="is-label">
        {{ $t('projects.form.secondStep.projectLimitMinCount') }}
      </div>
      <div class="is-indent">
        <AuInput
          v-model="minCount"
          clearable
          :placeholder="$t('projects.form.secondStep.projectLimitMinCountPlaceholder')"
          @input="checkMinCount"
        />
      </div>
      <div class="is-label">
        {{ $t('projects.form.secondStep.projectLimitOneHandLabel') }}
      </div>
      <div class="is-indent">
        <AuInput
          v-model="quote"
          clearable
          :placeholder="$t('projects.form.secondStep.projectLimitPlaceholder')"
          @input="checkQuote"
        />
      </div>
    </div>
    <div
      v-if="isTestRole"
      class="is-indent is-advanced"
      @click="showTest=!showTest"
    >
      <span>
        {{ $t("projects.form.secondStep.projectTest") }}
      </span>
      <AuIcon
        :icon="showTest ? 'chevron_up' : 'chevron_down'"
        clickable
        @click="showTest=!showTest"
      />
    </div>
    <div v-show="showTest">
      <div class="is-label">
        {{ $t('projects.form.secondStep.projectTokenQuoteLabel') }}<AuTooltip :description="'<div>Project token determined by project type:</div><li>USD — for investment project</li><li>AUT — for voting project</li>'" />
      </div>
      <div class="is-indent">
        <AuSelect
          :value="tokenQuote"
          :options="tokenQuotesOptions"
          required
          :placeholder="$t('projects.form.secondStep.projectTokenQuotePlaceholder')"
          @input="value => tokenQuote = value"
        />
      </div>
    </div>

    <div
      v-if="showError"
      class="error"
    >
      {{ errorMsg }}
    </div>
    <div class="d-flex">
      <AuButton
        class="mr-2"
        width="125px"
        bordered
        center
        @click="prevStep"
      >
        {{ $t("projects.form.backButton") }}
      </AuButton>

      <AuButton
        width="100%"
        type="primary"
        center
        @click="nextStep"
      >
        {{ $t("projects.form.nextButton") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  props: {
    id: {
      type: String,
      default: "new"
    },
    tokensCount: {
      type: String,
      default: null
    },
    showPriceRedeem: {
      type: Boolean,
      default: false
    },
    firstPartOfProjectInfo: {
      type: Object,
      default: () => {}
    },
    secondPartOfProjectInfo: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      minCount: null,
      minCol: "...",
      dateStart: null,
      dateEnd: null,
      section: null,
      priceBase: null,
      tokenMnemonic: "",
      priceRedeem: null,
      quote: null,
      expectedProfit: null,
      dateClose: null,
      showAdvanced: false,
      showTest: false,
      showError: false,
      errorMsg: null
    };
  },

  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      tokensUtility: "tokensUtility",
      sectionCapital: "sectionCapital",
      requestsToIssue: "requestsToIssue",
      defaultAutMnemonic: "defaultToken/defaultAutMnemonic",
      defaultTokenEnv: "defaultToken/defaultTokenEnv",
      profile: "profile/profile"
    }),

    isTestRole() {
      return _.some(this.profile.roles, role => role == "ROLE_TEST");
    },

    minDate() {
      return new Date(new Date().getTime()
      - new Date().getSeconds() * 1000
      - new Date().getMinutes() * 60000
      - new Date().getHours() * 3600000);
    },

    minDateAfterMinDate() {
      return new Date(this.dateStart
        ? this.dateStart.getTime() + (24 * 60 * 60 * 1000) * 2
        : this.minDate.getTime() + (24 * 60 * 60 * 1000) * 2);
    },

    tokenQuotesOptions() {
      const buf = new Set();
      _.forEach([...this.tokensFiat, ...this.tokensUtility], token => buf.add(token));
      return _.map([...buf.values()], token => ({ display: token.nameEng, value: `/api/au_tokens/${token.id}`, decimals: token.decimals }));
    },

    priceInMnemonic() {
      let envToken = this.defaultTokenEnv;
      if (this.firstPartOfProjectInfo?.projectType == "voting") {
        envToken = this.defaultAutMnemonic;
      }
      return envToken;
    },

    tokenQuote() {
      let envToken = this.defaultTokenEnv;
      if (this.firstPartOfProjectInfo?.projectType == "voting") {
        envToken = this.defaultAutMnemonic;
      }
      const result = _.find(this.tokensUtility, { mnemonicEng: envToken })?.id;
      return `/api/au_tokens/${result}`;
    },

    currentTokenDecimals() {
      const token = _.find(this.tokenQuotesOptions, { value: this.tokenQuote });
      if (token) {
        return token.decimals;
      }
      return 0;
    },

    capitalsSectionsOptions() {
      return _.map(this.sectionCapital, section => ({ display: section.name, value: `/api/section_capitals/${section.id}` }));
    },

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    },

    minCap() {
      const res = this.tokenQuote ? BigNumber(this.minCol).multipliedBy(this.priceBase).toString(10) : null;
      return !isNaN(res) ? res : "...";
    },

    maxCap() {
      const res = this.tokenQuote ? BigNumber(this.tokensCount).multipliedBy(this.priceBase).toString(10) : 0;
      return !isNaN(res) && res != 0 ? res : null;
    },

    mode() {
      return this.id === "new" ?
        this.$t("projects.form.secondStep.labelNew") :
        this.$t("projects.form.secondStep.labelEdit");
    },

    profit() {
      if (!this.showPriceRedeem) {
        return this.expectedProfit.replace(",", ".");
      }
      const pricePercent = ((this.priceRedeem - this.priceBase) * 100) / this.priceBase;
      const yearCoefficient = Math.floor(365 / moment(this.dateClose).diff(this.dateEnd, "days"));
      return pricePercent * yearCoefficient;
    },

    requiredFields() {
      return [
        { label: this.$t("projects.form.secondStep.projectDateStartLabel"), value: this.dateStart },
        { label: this.$t("projects.form.secondStep.projectDateEndLabel"), value: this.dateEnd },
        { label: this.$t("projects.form.secondStep.projectExpectedProfitLabel"), value: this.expectedProfit },
        { label: this.$t("projects.form.secondStep.projectSectionLabel"), value: this.section },
        { label: this.$t("projects.form.secondStep.projectPriceBaseLabel"), value: this.priceBase }
      ];
    }
  },

  async mounted() {
    if (this.secondPartOfProjectInfo) {
      this.minCount = this.secondPartOfProjectInfo.minCount,
      this.minCol = this.secondPartOfProjectInfo.minCol;
      this.dateStart = this.secondPartOfProjectInfo.dateStart;
      this.dateEnd = this.secondPartOfProjectInfo.dateEnd;
      this.section = this.secondPartOfProjectInfo.section;
      this.priceBase = this.secondPartOfProjectInfo.priceBase;
      this.tokenQuote = this.secondPartOfProjectInfo.tokenQuote;
      this.quote = this.secondPartOfProjectInfo.quote;
      this.tokenMnemonic = this.secondPartOfProjectInfo.tokenMnemonic;
      this.expectedProfit = this.secondPartOfProjectInfo.expectedProfit ? (this.secondPartOfProjectInfo.expectedProfit).toString(10).replace(".", ",") : "";
      if (this.showPriceRedeem) {
        this.priceRedeem = this.secondPartOfProjectInfo.priceRedeem;
        this.dateClose = this.secondPartOfProjectInfo.dateClose;
      }
    }

    if (_.isEmpty(this.requestsToIssue)) {
      await this.getHydraRequestToIssue();
    }
    if (_.isEmpty(this.sectionCapital)) {
      await this.getSectionCapital();
    }
    if (this.id !== "new" && !this.secondPartOfProjectInfo) {
      this.setFields(this.requestsToIssue.find(item => item.id == this.id));
    }
    if (!this.dateStart) {
      this.dateStart = new Date();
    }
    if (this.priceBase == 0) {
      this.priceBase = BigNumber(1).multipliedBy(BigNumber(10).pow(this.currentTokenDecimals)).toString(10);
    }
    this.checkEnvToken();
  },

  methods: {
    ...mapActions({
      getHydraRequestToIssue: "getHydraRequestToIssue",
      getSectionCapital: "getSectionCapital"
    }),

    checkEnvToken() {
      if (this.firstPartOfProjectInfo?.projectType == "invest") {
        this.setTokenQuote(this.defaultTokenEnv);
      }
      if (this.firstPartOfProjectInfo?.projectType == "voting") {
        this.setTokenQuote(this.defaultAutMnemonic);
      }
    },

    setExpectedProfit(value) {
      if (value <= 10000) {
        if (value == "00") {
          this.expectedProfit = "0";
        }
        else {
          this.expectedProfit = value;
        }
      }
      else {
        this.expectedProfit = "10000";
      }
    },

    checkRequiredFields() {
      const res = _.find(this.requiredFields, field => !field.value || field.value === "");
      this.errorMsg = res ? this.$t("projects.form.error") + ` ${res.label}` : null;
      return res === undefined;
    },

    setDateStart(value) {
      this.dateStart = this.getDate(value);

      if (this.dateEnd && this.dateEnd.getTime() < this.minDateAfterMinDate.getTime()) {
        this.dateEnd = null;
      }

      if (this.dateClose && this.dateClose.getTime() < this.minDateAfterMinDate.getTime()) {
        this.dateClose = null;
      }
    },

    setDateEnd(value) {
      this.dateEnd = this.getDate(value);
    },

    setDateClose(value) {
      this.dateClose = this.getDate(value);
    },

    getDate(value) {
      if (!value) {
        return value;
      }

      value = new Date(value.getTime() - 20 * 60000);

      if (value.getMinutes() < 30) {
        return new Date(value.getTime()
        - value.getSeconds() * 1000
        - value.getMinutes() * 60000);
      }
      else {
        return new Date(value.getTime()
        + (60 - value.getSeconds()) * 1000
        + (59 - value.getMinutes()) * 60000);
      }
    },

    checkInput(value) {
      const buf = value.match(/^[0-9]{1,}[.,]?[0-9]{0,2}/g);
      return buf ? buf[0].replace(",", ".") : null;
    },

    checkQuote() {
      this.quote = this.checkInput(this.quote);
      if (this.quote && BigNumber(this.quote).isGreaterThan(this.tokensCount)) {
        this.quote = this.quote.slice(0, -1);
      }
    },

    checkMinCount() {
      this.minCount = this.checkInput(this.minCount);
      if (this.minCount && BigNumber(this.minCount).isGreaterThan(this.tokensCount)) {
        this.minCount = this.minCount.slice(0, -1);
      }
    },

    prevStep() {
      let secondPartOfProjectInfo = {
        minCount: this.minCount,
        minCol: this.minCol,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        section: this.section,
        priceBase: this.priceBase,
        tokenQuote: this.tokenQuote,
        quote: this.quote,
        expectedProfit: this.profit.toString() == 0 ? "" : this.profit.toString(),
        tokenMnemonic: this.tokenMnemonic
      };
      if (this.showPriceRedeem) {
        secondPartOfProjectInfo.priceRedeem = this.priceRedeem;
        secondPartOfProjectInfo.dateClose = this.dateClose;
      }

      this.$emit("secondPartOfProjectInfo", secondPartOfProjectInfo);
      this.$emit("prevStep");
    },

    formatView(date) {
      return moment(date).locale("ru").format("L HH:mm");
    },

    setTokenQuote(value) {
      this.tokenMnemonic = value;
    },

    nextStep() {
      let secondPartOfProjectInfo = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        section: this.section,
        priceBase: this.priceBase,
        tokenQuote: this.tokenQuote,
        tokenMnemonic: this.tokenMnemonic,
        maxCap: this.maxCap,
        expectedProfit: this.profit.toString(),
        quote: this.quote,
        minCount: this.minCount
      };
      if (this.minCol) {
        secondPartOfProjectInfo.minCol = this.minCol;
      }
      if (this.minCap) {
        secondPartOfProjectInfo.minCap = this.minCap;
      }
      if (this.showPriceRedeem) {
        secondPartOfProjectInfo.priceRedeem = this.priceRedeem;
        secondPartOfProjectInfo.dateClose = this.dateClose;
      }
      if (this.checkRequiredFields()) {
        this.$emit("secondPartOfProjectInfo", secondPartOfProjectInfo);
        this.$emit("nextStep");
      }
      else {
        this.showError = true;
      }
    },

    goBack() {
      this.$router.push({ name: "my projects" });
    },

    setFields(data) {
      this.minCount = data.minCount,
      this.minCol = isNaN(BigNumber(data.minCap).dividedBy(data.priceBase).toString(10)) ? "" : BigNumber(data.minCap).dividedBy(data.priceBase).toString(10);
      this.dateStart = new Date(Date.parse(data.dateStart));
      this.dateEnd = new Date(Date.parse(data.dateEnd));
      this.section = data.section;
      this.priceBase = data.priceBase;
      this.tokenQuote = data.tokenQuote;
      this.quote = data.quote;
      this.expectedProfit = data.expectedProfit.toString(10).replace(".", ",");
      this.setTokenQuote(data.tokenQuote);
      if (this.showPriceRedeem) {
        this.priceRedeem = data.priceRedeem;
        this.dateClose = new Date(Date.parse(data.dateClose));
      }
    }
  }
};
</script>

<style lang="scss">
.form-header {
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: 500;
    font-size: 20px;
  }
}
.is2{
  font-weight: 400;
  font-size: 14px;
  padding: 20px;
}
.is-label{
  margin-bottom: 6px;
}
.is-line{
  width: 50%;
  height: 44px;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}
.input-wrapper {
  width: 100%;
  .dp__main {
    width: 100%;
  }
}
.is-indent{
  margin-bottom: 24px;
  gap: 10px;
}
.select-date {
  margin-bottom: 54px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 50px;
  }
}
.select-cap {
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}
.is-small-indent{
  margin-bottom: 15px;
}
.error {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: red;
}
.is-minmax{
  border: 1px solid #289566;
  border-radius: 12px;
  padding: 12px 15px 12px 12px;
}
.is-text-inside{
  color: var(--color-50);
  font-size: 13px;
  padding-right: 2px;
}
.is-calendar{
  border: 1px solid var(--color-80);
  border-radius: 12px;
  padding: 13px 15px 13px;
}
.mr-2 {
  margin-right: 10px;
}
.is-advanced {
  cursor: pointer;
  display: flex;
  justify-content: center;
  span {
    font-size: 14px;
    text-decoration: underline;
  }
}
</style>