<template>
  <div class="is1 card">
    <div class="form-header">
      <div class="title">
        {{ `${$t('projects.form.firstStep.labelFirstPart')} ${mode} ${$t('projects.form.firstStep.labelLastPart')}` }}
      </div>
      <AuButton
        width="auto"
        bordered
        center
        @click="goBack"
      >
        {{ $t('projects.form.cancelButton') }}
      </AuButton>
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectType') }}<AuTooltip :description="`<div>Voting project used to collect ${defaultAutMnemonic} as vote weight to determine which project is preferable for furter investment.</div> <div>After completion or decline vote fund will be returned to investor back.</div>`" />
    </div>
    <div class="is-indent">
      <AuSelect
        :value="projectType"
        :options="projectTypeOptions"
        required
        clearable
        :placeholder="$t('projects.form.firstStep.projectTypePlaceholder')"
        @input="value => projectType = value"
      />
    </div>
    <div class="is-naming">
      <div class="label-name">
        {{ $t('projects.form.firstStep.projectNameLabel') }}<AuTooltip :description="'The short name of your project. (at least 10 characters)'" />
      </div>
      <div class="label-abbr">
        {{ $t('projects.form.firstStep.projectAbbrNameLabel') }}
      </div>
      <AuInput
        v-model="projectNameEng"
        :has-error="projectNameEngHasError"
        class="input-name"
        :placeholder="$t('projects.form.firstStep.projectNamePlaceholder')"
        required
        clearable
      />
      <AuInput
        :model-value="projectAbbrNameEng"
        :has-error="projectAbbrNameEngHasError"
        :maxlength="8"
        class="input-abbr"
        :placeholder="$t('projects.form.firstStep.projectAbbrNamePlaceholder')"
        required
        clearable
        @update:modelValue="setProjectAbbrNameEng"
      />
      <!-- :mask="/^[a-zA-Z]+[a-zA-Z\d ]*$/" -->
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectDescLabel') }}
    </div>
    <div class="is-indent">
      <AuInput
        v-model="projectDescriptionEng"
        type="TextArea"
        text-area-heigth="75"
        required
        clearable
        :placeholder="$t('projects.form.firstStep.projectDescEngPlaceholder')"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectTokensCountLabel') }}
    </div>
    <div class="is-indent">
      <AuInput
        v-model="tokensCount"
        required
        :placeholder="$t('projects.form.firstStep.projectTokensCountPlaceholder')"
        type="Mask"
        :mask="Number"
      />
    </div>
    <div class="is-label">
      {{ $t('projects.form.firstStep.projectCompanyLabel') }}
    </div>
    <div class="is-indent">
      <AuSelect
        :value="company"
        :options="companiesOptions"
        :placeholder="$t('projects.form.firstStep.projectCompanyPlaceholder')"
        required
        clearable
        @input="value => company = value"
        @clearValue="company = null"
      />
    </div>

    <!-- <div class="is-label">
      {{ $t('projects.form.firstStep.projectCryptoTypeLabel') }}
    </div>
    <div class="is-indent">
      <AuSelect
        :value="cryptoActiveType"
        :options="cryptoActiveTypeOptions"
        required
        clearable
        disabled
        :placeholder="$t('projects.form.firstStep.projectCryptoTypePlaceholder')"
        @input="changeCryptoActiveType"
      />
    </div>
    <div
      v-if="showInvestType"
      class="is-label"
    >
      {{ $t('projects.form.firstStep.projectInvestTypeLabel') }}
    </div>
    <div
      v-if="showInvestType"
      class="is-indent"
    >
      <AuSelect
        :value="investType"
        :options="investmentTypeOptions"
        required
        clearable
        disabled
        :placeholder="$t('projects.form.firstStep.projectInvestTypePlaceholder')"
        @input="value => investType = value"
      />
    </div>
    <div
      v-if="showIsVoiting"
      class="is-label"
    >
      {{ $t('projects.form.firstStep.projectVoitingTypeLabel') }}
    </div>
    <div
      v-if="showIsVoiting"
      class="is-indent"
    >
      <AuSelect
        :value="isVoiting"
        :options="isVoitingOptions"
        required
        clearable
        :placeholder="$t('projects.form.firstStep.projectVoitingTypePlaceholder')"
        @input="value => isVoiting = value"
      />
    </div> -->

    <div
      v-if="isTestRole"
      class="is-indent is-extended"
      @click="showExtended=!showExtended"
    >
      <span>
        {{ $t("projects.form.firstStep.projectExtended") }}
      </span>
      <AuIcon
        :icon="showExtended ? 'chevron_up' : 'chevron_down'"
        clickable
        @click="showExtended=!showExtended"
      />
    </div>
    <div
      v-if="showExtended"
      class="is-hidden-block is-indent"
    >
      <div class="checkbox">
        <input
          id="burn"
          v-model="burnUnsoldTokens"
          type="checkbox"
        >
        <label for="burn">{{ $t("projects.form.firstStep.projectBurnUnsoldTokens") }}</label>
      </div>
      <div class="checkbox">
        <input
          id="autodeployTokens"
          v-model="autodeployTokens"
          type="checkbox"
        >
        <label for="autodeployTokens">{{ $t("projects.form.firstStep.projectAutodeploy") }}</label>
      </div>
    </div>

    <div
      v-if="showError"
      class="error"
    >
      {{ errorMsg }}
    </div>
    <AuButton
      width="100%"
      type="primary"
      center
      @click="nextStep"
    >
      {{ $t('projects.form.nextButton') }}
    </AuButton>
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    id: {
      type: String,
      default: "new"
    },
    firstPartOfProjectInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      projectNameEng: null,
      projectNameEngHasError: false,
      projectAbbrNameEng: null,
      projectAbbrNameEngHasError: false,
      projectDescriptionRus: null,
      projectDescriptionEng: null,
      tokensCount: "...",
      company: null,
      projectType: "invest",
      cryptoActiveType: "investment",
      investType: "non-redeemable",
      isVoiting: false,
      errorMsg: null,
      showError: false,
      showExtended: false,
      burnUnsoldTokens: true,
      autodeployTokens: true,
      projectTypeOptions: [
        {
          display: this.$t("projects.projectTypes.voting"),
          value: "voting"
        },
        {
          display: this.$t("projects.projectTypes.invest"),
          value: "invest"
        }
      ],
      cryptoActiveTypeOptions: [
        {
          display: this.$t("projects.cryptoActiveTypes.investment"),
          value: "investment"
        },
        {
          display: this.$t("projects.cryptoActiveTypes.access"),
          value: "access"
        },
        {
          display: this.$t("projects.cryptoActiveTypes.ticket"),
          value: "ticket"
        },
        {
          display: this.$t("projects.cryptoActiveTypes.voucher"),
          value: "voucher"
        },
        {
          display: this.$t("projects.cryptoActiveTypes.management"),
          value: "management"
        }
      ],
      investmentTypeOptions: [
        {
          display: this.$t("projects.investType.redeemable"),
          value: "redeemable"
        },
        {
          display: this.$t("projects.investType.non-redeemable"),
          value: "non-redeemable"
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      language: "language",
      companies: "companies",
      requestsToIssue: "requestsToIssue",
      defaultAutMnemonic: "defaultToken/defaultAutMnemonic",
      profile: "profile/profile"
    }),

    isTestRole() {
      return _.some(this.profile.roles, role => role == "ROLE_TEST");
    },

    showIsVoiting() {
      return this.investType == "non-redeemable";
    },

    showInvestType() {
      return this.cryptoActiveType == "investment";
    },

    companiesOptions() {
      return _.map(this.companies, (company) => ({
        display: this.language == "ru" ? company.nameRus : company.nameEng,
        value: `/api/companies/${company.id}`
      }));
    },

    mode() {
      return this.id === "new" ?
        this.$t("projects.form.firstStep.labelNew") :
        this.$t("projects.form.firstStep.labelEdit");
    },

    isVoitingOptions() {
      return [
        {
          display: this.$t("projects.form.firstStep.yes"),
          value: true
        },
        {
          display: this.$t("projects.form.firstStep.no"),
          value: false
        }
      ];
    },

    requiredFields() {
      return [
        { label: this.$t("projects.form.firstStep.projectNameLabel"), value: this.projectNameEng },
        { label: this.$t("projects.form.firstStep.projectAbbrNameLabel"), value: this.projectAbbrNameEng },
        { label: this.$t("projects.form.firstStep.projectDescLabel"), value: this.projectDescriptionEng },
        { label: this.$t("projects.form.firstStep.projectTokensCountLabel"), value: this.tokensCount },
        { label: this.$t("projects.form.firstStep.projectCompanyLabel"), value: this.company }
      ];
    }
  },

  watch: {
    companies() {
      const options = this.companiesOptions;
      if (options.length == 1 && !this.company) {
        this.company = options[0].value;
      }
    }
  },

  async mounted() {
    if (this.firstPartOfProjectInfo) {
      this.projectNameEng = this.firstPartOfProjectInfo.projectNameEng;
      this.projectAbbrNameEng = this.firstPartOfProjectInfo.projectAbbrNameEng;
      this.projectDescriptionRus = this.firstPartOfProjectInfo.projectDescriptionEng;
      this.projectDescriptionEng = this.firstPartOfProjectInfo.projectDescriptionEng;
      this.tokensCount = this.firstPartOfProjectInfo.tokensCount;
      this.company = this.firstPartOfProjectInfo.company;
      this.cryptoActiveType = this.firstPartOfProjectInfo.cryptoActiveType;
      this.projectType = this.firstPartOfProjectInfo.projectType;
      this.burnUnsoldTokens = this.firstPartOfProjectInfo.burnUnsoldTokens;
      this.autodeployTokens = this.firstPartOfProjectInfo.autodeployTokens;
      if (this.firstPartOfProjectInfo.investType) {
        this.investType = this.firstPartOfProjectInfo.investType;
      }
      if (this.showIsVoiting) {
        this.isVoiting = this.firstPartOfProjectInfo.isVoiting;
      }
    }

    if (_.isEmpty(this.requestsToIssue)) {
      await this.getHydraRequestToIssue();
    }
    if (_.isEmpty(this.companies)) {
      await this.getCompanies();
      if (this.companiesOptions.length === 1 && !this.company) {
        this.company = this.companiesOptions[0]?.value;
      }
    }
    if (this.id !== "new" && !this.firstPartOfProjectInfo) {
      this.setFields(this.requestsToIssue.find(item => item.id == this.id));
    }

    const options = this.companiesOptions;
    if (options.length == 1) {
      this.company = options[0].value;
    }
  },

  methods: {
    ...mapActions({
      getHydraRequestToIssue: "getHydraRequestToIssue",
      getCompanies: "getCompanies"
    }),

    setProjectAbbrNameEng(value) {
      this.projectAbbrNameEng = value.toUpperCase();
    },

    checkRequiredFields() {
      const res = _.find(this.requiredFields, field => !field.value || field.value === "");
      this.errorMsg = res ? this.$t("projects.form.error") + ` ${res.label}` : null;
      return res === undefined;
    },

    checkProjectNameEng() {
      const regexStartWithLetter = /^[a-zA-Z]+.*$/;
      const foundStartWithLetter = this.projectNameEng.match(regexStartWithLetter);
      if (!foundStartWithLetter) {
        this.errorMsg = "The name must start with a latin letter";
        return false;
      }

      this.projectNameEngHasError = false;
      return true;
    },

    checkProjectAbbrNameEng() {
      const regexStartWithLetter = /^[a-zA-Z]+.*$/;
      const foundStartWithLetter = this.projectAbbrNameEng.match(regexStartWithLetter);
      if (!foundStartWithLetter) {
        this.errorMsg = "The token symbol / mnemonic must start with a latin letter";
        return false;
      }

      const regexContainLatin = /^[a-zA-Z]+[a-zA-Z\d ]*$/;
      const foundContainLatin = this.projectAbbrNameEng.match(regexContainLatin);
      if (!foundContainLatin) {
        this.errorMsg = "The token symbol / mnemonic must contain only latin characters and numbers";

        return false;
      }

      this.projectAbbrNameEngHasError = false;
      return true;
    },

    nextStep() {
      let firstPartOfProjectInfo = {
        projectNameEng: this.projectNameEng,
        projectAbbrNameEng: this.projectAbbrNameEng,
        projectDescriptionRus: this.projectDescriptionEng,
        projectDescriptionEng: this.projectDescriptionEng,
        tokensCount: this.tokensCount,
        company: this.company,
        projectType: this.projectType,
        cryptoActiveType: this.cryptoActiveType,
        burnUnsoldTokens: this.burnUnsoldTokens,
        autodeployTokens: this.autodeployTokens
      };
      if (this.showInvestType) {
        firstPartOfProjectInfo.investType = this.investType;
      }
      if (this.showIsVoiting) {
        firstPartOfProjectInfo.isVoiting = this.isVoiting;
      }

      if (this.checkRequiredFields()) {
        if (!this.checkProjectNameEng()) {
          this.showError = true;
          this.projectNameEngHasError = true;
          return;
        }

        if (!this.checkProjectAbbrNameEng()) {
          this.showError = true;
          this.projectAbbrNameEngHasError = true;
          return;
        }

        this.$emit("firstPartOfProject", firstPartOfProjectInfo);
        this.$emit("nextStep");
      }
      else {
        this.showError = true;
      }
    },

    changeCryptoActiveType(value) {
      this.cryptoActiveType = value;
      this.investType = null;
      this.isVoiting = null;
    },

    goBack() {
      this.$router.push({ name: "my projects" });
    },

    setFields(data) {
      if (!data) {
        return;
      }

      this.projectNameEng = data.tokenNameEng;
      this.projectAbbrNameEng = data.tokenMnemonicEng;
      this.projectDescriptionRus = data.descriptionCapitalEng;
      this.projectDescriptionEng = data.descriptionCapitalEng;
      this.tokensCount = data.tokenCount;
      this.company = data.company;
      this.projectType = data.projectType;
      this.cryptoActiveType = data.cryptoActiveType;
      this.burnUnsoldTokens = data.burnUnsoldTokens;
      this.autodeployTokens = data.autodeployTokens;
      if (this.showInvestType) {
        this.investType = data.investType;
      }
      if (this.showIsVoiting) {
        this.isVoiting = data.isVoiting;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-header {
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: 500;
    font-size: 20px;
  }
}
.error {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: red;
}

.is1{
  font-family: 'Suisse Intl',serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 20px;
}
.is-label{
  margin-bottom: 6px;
}
.is-line{
  width: 50%;
}
.is-indent{
  margin-bottom: 24px;
  gap: 10px;
}
.is-small-indent{
  margin-bottom: 15px;
}
.is-next{
  border: 1px dashed #289566;
  border-radius: 14px;
  padding: 12px;
  &:hover {
    cursor: pointer;
  }
}
.is-naming {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "a b" "c d";
  gap: 7px 10px;

  .label-name {
    grid-area: a;
  }
  .label-abbr {
    grid-area: b;
  }
  .input-name {
    grid-area: c;
    @media screen and (max-width: 1000px) {
      margin-bottom: 14px;
    }
  }
  .input-abbr {
    grid-area: d;
  }

  margin-bottom: 24px;
  @media screen and (max-width: 1000px) {
    grid-template-areas: "a a" "c c" "b b" "d d";
  }
}
.is-extended {
  cursor: pointer;
  display: flex;
  justify-content: center;
  span {
    font-size: 14px;
    text-decoration: underline;
  }
}
.is-hidden-block {
  display: flex;
  flex-direction: column;
  input:hover {
    cursor: pointer;
  }
  label:hover {
    cursor: pointer;
  }
  .checkbox{
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
</style>