<template>
  <div>
    <AuBreadcrumb
      parent-name="Dashboard"
    />
    <div class="card">
      <div class="header">
        <div class="title">
          Project investors
        </div>
      </div>
      <AuTable
        class="projects-table"
        :columns="columns"
        :loading="dataLoading"
        :items="items"
      />
      <AuPagination
        v-if="investorsTotal > itemsPerPage"
        :total-items="investorsTotal"
        @pageChanged="changePage"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectInvestors",
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      columns: [
        { name: "Name", align: "start" },
        { name: "Last name", align: "start" },
        { name: "E-mail", align: "start" },
        { name: "Аmount ", align: "start" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      investors: "capitals/investors",
      investorsTotal: "capitals/investorsTotal",
      dataLoading: "capitals/dataLoading"
    }),

    items() {
      return _.map(this.investors, item => ({
        iName: item.user.iName,
        fName: item.user.fName,
        email: item.user.email,
        value: item.value
      }));
    }
  },
  async mounted() {
    await this.getItems();
  },
  methods: {
    ...mapActions({
      getCapitalInvestor: "capitals/getCapitalInvestor"
    }),

    async getItems() {
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        auCapital: this.id
      };
      await this.getCapitalInvestor(params);
    },

    async changePage() {
      this.page++;
      await this.getItems();
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 100%;
  padding: 20px 22px 22px 20px;
}
.header {
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  color: var(--text-color);
  line-height: 1.1;
  margin-bottom: 20px;
}
.projects-table {
  margin-bottom: 0;
}

</style>