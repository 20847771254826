<template>
  <ProgressLoader
    v-if="breadcrumbLoading"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />

  <div v-else>
    <AuBreadcrumb
      :pages="breadcrumb"
      parent-name="Dashboard"
    />
    <div class="card dividends">
      <div class="header">
        <div class="title">
          {{ $t('dividendsIssuer.label') }}
        </div>
        <AuButton
          bordered
          center
          width="237"
          type="primary"
          @click="actionAdd"
        >
          <span class="bnt-text">
            {{ $t('dividendsIssuer.titleButton') }}
          </span>
        </AuButton>
      </div>
      <AuTable
        :columns="columns"
        :items="items"
        :loading="dataLoading"
      >
        <template #action="{value}">
          <div
            v-click-outside="closeAllMenus"
            class="container-menu"
          >
            <AuIcon
              icon="more"
              as-button
              @click="openMenu(value.index)"
            />
            <div
              v-if="showMenu[value.index]"
              class="context-menu"
            >
              <AuButton
                width="100%"
                center
                popup
                border-color="none"
                active-bg-color="none"
                hovered-bg-color="none"
                @click="actionEdit(value)"
              >
                {{ $t('dividendsIssuer.editMenu') }}
              </AuButton>
              <AuButton
                width="100%"
                center
                popup
                border-color="none"
                active-bg-color="none"
                hovered-bg-color="none"
                @click="actionDelete(value)"
              >
                {{ $t('dividendsIssuer.deleteMenu') }}
              </AuButton>
              <AuButton
                width="100%"
                center
                popup
                border-color="none"
                active-bg-color="none"
                hovered-bg-color="none"
                @click="doProcess(value)"
              >
                {{ value.status == "draft" ?
                  $t('dividendsIssuer.complyMenu') : $t('dividendsIssuer.historyMenu') }}
              </AuButton>
            </div>
          </div>
        </template>
      </AuTable>
      <AuPagination
        v-if="dividendsTotal > itemsPerPage"
        :total-items="dividendsTotal"
        @pageChanged="changePage"
      />
    </div>
    <AuModal
      v-model="actionDialog"
      width="500px"
    >
      <template #header>
        <span class="modal-head-text">
          {{ modalHeader }}
        </span>
      </template>
      <template #body>
        <div v-if="actionMode !== 'delete'">
          <div class="modal-label">
            {{ $t('dividendsIssuer.modalLabelFirst') }}
          </div>
          <AuInput
            v-model="title"
            type="Text"
          />
          <div class="modal-label mt-2">
            {{ $t('dividendsIssuer.modalLabelSecond') }}
          </div>
          <AuInput
            v-model="description"
            type="TextArea"
            text-area-heigth="100"
          />
          <div class="modal-label mt-2">
            {{ $t('dividendsIssuer.modalLabelThird') }}
          </div>
          <AuSelect
            :value="period"
            :options="periodOptions"
            @input="v => period = v"
          />
          <div class="modal-label mt-2">
            {{ $t('dividendsIssuer.modalLabelFourth') }}
          </div>
          <AuSelect
            :value="tokenQuote"
            :options="tokenQuotesOptions"
            @input="v => tokenQuote = v"
          />
          <div class="modal-label mt-2">
            {{ $t('dividendsIssuer.modalLabelFifth') }}
          </div>
          <AuInput
            v-model="date"
            type="Date"
            :min-date="new Date()"
            :format="formatView"
            enable-time-picker
            clearable
          />
          <div class="modal-label mt-2">
            {{ $t('dividendsIssuer.modalLabelSixth') }}
          </div>
          <AuInput
            v-model="amount"
            type="Number"
          />
        </div>
        <div v-else>
          {{ $t('dividendsIssuer.modalBody') }}
        </div>
      </template>
      <template #footer>
        <div
          v-if="!allFields"
          class="error"
        >
          Fill in all the fields
        </div>
        <div class="modal-button">
          <AuButton
            type="primary"
            center
            width="125px"
            :disabled="!allFields"
            @click="doAction"
          >
            {{ modalButton }}
          </AuButton>
          <AuButton
            bordered
            center
            width="125px"
            @click="actionDialog=false"
          >
            {{ $t('dividendsIssuer.modalCancelButton') }}
          </AuButton>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import ProgressLoader from "@/loaders/progress-bar";

export default {
  name: "DididendsIssuer",

  components: {
    ProgressLoader
  },

  props: {
    id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      showMenu: [],
      columns: [
        { name: this.$t("dividendsIssuer.firstColumn"), align: "start" },
        { name: this.$t("dividendsIssuer.secondColumn") },
        { name: this.$t("dividendsIssuer.thirdColumn") },
        { name: this.$t("dividendsIssuer.fourthColumn") },
        { name: this.$t("dividendsIssuer.fifthColumn") },
        { name: this.$t("dividendsIssuer.sixthColumn") },
        { name: this.$t("dividendsIssuer.seventhColumn"), slot: "action" }
      ],
      periodOptions: [
        { display: this.$t("dividendsIssuer.periodMonth"), value: "month" },
        { display: this.$t("dividendsIssuer.periodQuarter"), value: "quarter" },
        { display: this.$t("dividendsIssuer.periodYear"), value: "year" }
      ],
      statusNames: {
        "draft": this.$t("dividendsIssuer.statusDraft"),
        "process": this.$t("dividendsIssuer.statusProcess")
      },
      page: 1,
      itemsPerPage: 30,
      actionDialog: false,
      actionMode: null,
      actionId: null,
      actionIndex: null,
      title: null,
      description: null,
      period: null,
      tokenQuote: null,
      amount: "...",
      date: null,
      auCapital: null,
      breadcrumbLoading: true
    };
  },

  computed: {
    ...mapGetters({
      dividends: "dividends/dividends",
      dividendsTotal: "dividends/dividendsTotal",
      dataLoading: "dividends/dataLoading",
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold"
    }),

    breadcrumb() {
      const breadcrumb = [
        { key: 0, name: "Dashboard", path: "/" }
      ];

      if (this.capitalData) {
        breadcrumb.splice(1, 0, { key: 1, name: this.capitalData.tokenBase?.nameEng, path: "/project-card/" + this.capitalData.id });
      }

      return breadcrumb;
    },

    items() {
      return _.map(this.dividends, (item, index) => {
        const mnemonic = _.get(
          _.find(this.tokenQuotesOptions, value => item.tokenQuote == value.value),
          "mnemonic",
          "");
        const period = _.get(
          _.find(this.periodOptions, value => item.period == value.value),
          "display",
          "-");
        return {
          name: item.title,
          description: item.description,
          period: period,
          amount: item.amount + " " + mnemonic,
          status: _.get(this.statusNames, item.status, "-"),
          date: moment(item.endAt).format("DD.MM.YYYY hh:mm"),
          action: {
            id: item.id,
            index: index,
            title: item.title,
            description: item.description,
            period: item.period,
            tokenQuote: item.tokenQuote,
            amount: item.amount,
            status: item.status,
            date: item.endAt
          }
        };
      });
    },

    tokenQuotesOptions() {
      let allTokens = [...this.tokensFiat, ...this.tokensGold];
      return _.map(allTokens, token => ({
        display: token.nameEng,
        value: `/api/au_tokens/${token.id}`,
        mnemonic: token.mnemonicEng
      }));
    },

    modalHeader() {
      return this.actionMode === "add" ?
        this.$t("dividendsIssuer.modalAddHeader") :
        this.actionMode === "edit" ?
          this.$t("dividendsIssuer.modalEditHeader") : this.$t("dividendsIssuer.modalDeleteHeader");
    },

    modalButton() {
      return this.actionMode === "add" ?
        this.$t("dividendsIssuer.modalAddButton") :
        this.actionMode === "edit" ?
          this.$t("dividendsIssuer.modalEditButton") : this.$t("dividendsIssuer.modalDeleteButton");
    },

    allFields() {
      return this.amount > 0 && this.title && this.date && this.period && this.tokenQuote;
    },

    capitalData() {
      return _.first(this.auCapital);
    }
  },

  async mounted() {
    this.auCapital = await this.getAuCapitalsByParams({ tokenBaseId: this.id });
    this.breadcrumbLoading = false;

    await this.getDividends({
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      auTokenId: this.id
    });

    this.closeAllMenus();
  },

  methods: {
    ...mapActions({
      getDividends: "dividends/getDividends",
      addDividends: "dividends/addDividends",
      editDividends: "dividends/editDividends",
      deleteDividends: "dividends/deleteDividends",
      getAuCapitalsByParams: "capitals/getAuCapitalsByParams"
    }),

    formatView(date) {
      return moment(date).format("DD:MM:YYYY hh:mm ");
    },

    closeAllMenus() {
      this.showMenu = _.map(this.requestsToIssue, () => false);
    },

    openMenu(index) {
      this.closeAllMenus();
      this.showMenu[index] = true;
    },

    async changePage(page) {
      this.page = page;
      await this.getDividends({
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        auTokenId: this.id
      });
    },

    actionAdd() {
      this.actionMode = "add";
      this.actionId = null;
      this.actionIndex = null;
      this.title = null;
      this.description = null;
      this.period = null;
      this.tokenQuote = null;
      this.amount = null;
      this.date = null;
      this.actionDialog = true;
    },

    actionEdit(item) {
      this.actionMode = "edit";
      this.actionId = item.id;
      this.actionIndex = item.index;
      this.title = item.title;
      this.description = item.description;
      this.period = item.period;
      this.tokenQuote = item.tokenQuote;
      this.amount = item.amount;
      this.date = item.date;
      this.actionDialog = true;
    },

    showChanges(index) {
      this.dividends[index].title = this.title;
      this.dividends[index].description = this.description;
      this.dividends[index].period = this.period;
      this.dividends[index].amount = this.amount;
      this.dividends[index].tokenQuote = this.tokenQuote;
      this.dividends[index].date = this.date;
    },

    actionDelete(item) {
      this.actionMode = "delete";
      this.actionId = item.id;
      this.actionIndex = item.index;
      this.actionDialog = true;
    },

    doAction() {
      this.actionDialog = false;
      switch (this.actionMode) {
        case "add": this.add(); break;
        case "edit": this.edit(); break;
        case "delete": this.del(); break;
      }
    },

    doProcess(item) {
      this.actionId = item.id;
      this.actionIndex = item.index;
      if (item.status == "draft") {
        this.perform();
      }
      else {
        this.$router.push({ name: "dividends history", params: { id: item.id } });
      }
    },

    async add() {
      const params = {
        title: this.title,
        description: this.description,
        auToken: `/api/au_tokens/${this.id}`,
        period: this.period,
        tokenQuote: this.tokenQuote,
        amount: this.amount,
        status: "draft",
        endAt: this.date
      };
      try {
        const resp = await this.addDividends(params);
        this.dividends.push(resp.data);
        this.showSuccess(this.$t("dividendsIssuer.success.add"));
      }
      catch (e) {
        this.showError(this.$t("dividendsIssuer.error.add"));
      }
    },

    async edit() {
      const body = {
        id: this.actionId,
        title: this.title,
        description: this.description,
        auToken: `/api/au_tokens/${this.id}`,
        period: this.period,
        tokenQuote: this.tokenQuote,
        amount: this.amount,
        endAt: this.date
      };
      try {
        await this.editDividends({ id: this.actionId, body: body });
        this.showChanges(this.actionIndex);
        this.showSuccess(this.$t("dividendsIssuer.success.edit"));
      }
      catch (e) {
        this.showError(this.$t("dividendsIssuer.error.edit"));
      }
    },

    async del() {
      try {
        await this.deleteDividends(this.actionId);
        this.dividends.splice(this.actionIndex, 1);
        this.showSuccess(this.$t("dividendsIssuer.success.delete"));
      }
      catch (e) {
        this.showError(this.$t("dividendsIssuer.error.delete"));
      }
    },

    perform() {
      let body = _.find(this.dividends, item => item.id == this.actionId);
      body.percent = body.percent.toString();
      body.status = "process";
      body.percent = body.percent.toString();
      try {
        this.editDividends({ id: this.actionId, body: body });
        this.showSuccess(this.$t("dividendsIssuer.success.perform"));
      }
      catch (e) {
        this.showError(this.$t("dividendsIssuer.error.perform"));
      }
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    }
  }
};
</script>

<style scoped lang="scss">
.dividends {
  width: 100%;
  padding: 20px 22px 22px 20px;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-size: 24px;
      color: var(--text-color);
      line-height: 1.1;
      margin-bottom: 20px;
    }
  }
}
.modal-head-text {
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}
.modal-button {
  display: flex;
  justify-content: end;
  gap: 15px;
  margin-top: 12px;
}
.modal-label {
  font-weight: 400;
  font-size: 12px;
  padding-left: 7px;
}
.container-menu{
  position: relative;
}
.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 128px;
  right: calc(50% - 128px / 2);
  background: var(--menu-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  z-index: 10;
  top: 30px;
}
.error {
  font-size: 12px;
}
.bnt-text{
  font-weight: 450;
  font-size: 12px;
}
</style>