<template>
  <div>
    <AuBreadcrumb
      :pages="breadcrumb"
      :current-page-title="correctToken.nameEng || '...'"
    />

    <div class="card">
      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div v-else>
        <div>
          <div class="d-flex justify-content-between token-header">
            <div class="logo-wrap">
              <img
                class="logo"
                :src="tokenIcon"
                alt="cryptoactive"
              >

              {{ correctToken.nameEng }}
            </div>
            <div v-if="id!=defaultTokenId">
              <span>1 {{ correctToken.mnemonicEng }} <AuIcon
                class="mb"
                icon="swap_horizontal_green"
              /> <AuDecimalStatic
                :value="course"
                :show-addition="false"
              /> {{ defaultTokenMnemonic }}</span>
            </div>
          </div>
          <div class="mt-2">
            <span class="balance">

              <div class="token-balance">
                Token balance
              </div>

              <AuDecimalStatic
                class="balance__value"
                :value="balance"
                :addition="correctToken.mnemonicEng"
                :indent="correctToken.indent"
                :decimal="correctToken.decimals"
              />

            </span>
          </div>
          <div
            v-if="blocked != 0"
            class="withdraw-balance"
          >
            {{ $t("tokeninfo.blocked") }}
            <AuDecimalStatic
              :value="blocked"
              :addition="correctToken.mnemonicEng"
              :indent="correctToken.indent"
              :decimal="correctToken.decimals"
            />
          </div>
          <div class="token-buttons__panel">
            <div class="token-buttons__address">
              <AuIcon
                icon="copy"
                clickable
                @click="copyToClipboard"
              />

              <div
                class="action-button"
                @click="goToScanner"
              >
                {{ $filters.shortAddress(correctToken.createdAddress) }}
              </div>
            </div>

            <div class="token-buttons">
              <div class="token-buttons__block">
                <AuButton
                  v-if="isDefaultToken"
                  center
                  :min-size="true"
                  type="primary"
                  @click="$router.push({ name: 'buy form' })"
                >
                  {{ $t("briefcase.changeAction") }}
                </AuButton>

                <AuButton
                  v-if="isDefaultToken"
                  center
                  :min-size="true"
                  type="primary"
                  @click="$router.push({ name: 'withdraw' })"
                >
                  {{ $t("briefcase.bringOutAction") }}
                </AuButton>

                <AuButton
                  center
                  bordered
                  :min-size="true"
                  type="primary"
                  @click="$router.push({ name: 'send token', params: { id: id } })"
                >
                  {{ $t("briefcase.sendAction") }}
                </AuButton>
              </div>
            </div>
          </div>
          <div
            v-if="transactions.length != 0"
            class="filter-panel"
          >
            <Datepicker
              v-model="date"
              :locale="language"
              auto-apply
              :max-date="new Date()"
              :enable-time-picker="false"
              :format="format"
              :placeholder="$t('transactions.datePickerPlaceholder')"
              range
              :dark="!isLightTheme"
              @update:model-value="changeDate"
            />

            <AuSelect
              :value="type"
              class="type-select"
              :options="typeArray"
              :placeholder="$t('transactions.transactionType.placeholder')"
              @input="changeType"
            />
          </div>
        </div>

        <ProgressLoader
          v-if="loadingLocal"
          mode="indeterminate"
          color="#5AC396"
          size="150px"
        />

        <div
          v-if="!empty"
        >
          <div class="card__header-line">
            <div class="card__header-date">
              <span>{{ typeTitle }} </span> <span class="card__header-month"> {{ dateTitle }} </span>
            </div>

            <div class="card__action">
              <div class="fs14">
                <span
                  v-if="!spending.isPositive"
                  class="mr"
                >-</span>
                <AuDecimalStatic
                  :value="spending.sum.toString()"
                  :indent="correctToken.indent"
                  :addition="defaultTokenMnemonic"
                  :decimal="correctToken.decimals"
                />
              </div>
            </div>
          </div>

          <div class="transactions-sector">
            <div v-if="transactions.length">
              <div
                v-for="(group, groupIndex) in transactions"
                :key="groupIndex"
                class="transactions-group"
              >
                <div class="period">
                  {{ group.date }}
                </div>

                <div class="transactions">
                  <LastTransaction
                    v-for="(transaction, index) in group.transactions"
                    :key="index"
                    class="transaction"
                    :icon="transaction.icon"
                    :name="transaction.name"
                    :link="transaction.link"
                    :transaction-type="transaction.type"
                    :amount="transaction.amount"
                    :is-refill="transaction.isRefill"
                    :card-type="transaction.cardType"
                    :decimals="transaction.decimals"
                    :mnemonic="transaction.mnemonic"
                    :hash="transaction.hash"
                    :address-eth="transaction.addressEth"
                    :address-from="transaction.addressFrom"
                    :address-to="transaction.addressTo"
                    :is-confirmed="transaction.isConfirmed"
                    :project-id="transaction.projectId"
                    :project-name="transaction.projectName"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="transactions-footer">
            <AuButton
              v-if="showGetMore < totalItems"
              height="42px"
              font-size="12px"
              bordered
              @click="getMore"
            >
              {{ $t("tokeninfo.loadMore") }}
            </AuButton>
          </div>
        </div>
        <div v-if="!transactions.length">
          <AuNoData
            :text="'noTrx'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import Datepicker from "@vuepic/vue-datepicker";
import LastTransaction from "@/components/lastTransactions/LastTransaction";
import MetaMask from "@/mixins/MetaMask";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "TokenInfo",

  components: {
    LastTransaction,
    Datepicker,
    ProgressLoader
  },

  mixins: [MetaMask],

  props: {
    id: {
      type: String,
      default: "0"
    }
  },

  data() {
    return {
      breadcrumb: [
        { key: 0, name: "Dashboard", path: "/" },
        { key: 1, name: "My assets", path: "/briefcase" }
      ],
      currencySup: new CurrencySupport(),
      scanURL: process.env.VUE_APP_SCAN_BASE_URL,
      addressEth: null,
      transactions: [],
      page: 1,
      totalItems: 1,
      showGetMore: 0,
      balance: null,
      loading: false,
      loadingLocal: false,
      cryptoBalance: null,
      spotBalance: null,
      blocked: null,
      correctToken: {},
      typeArray: [{ display: this.$t("transactions.transactionType.all"), value: "all" },
        { display: this.$t("transactions.transactionType.transfer"), value: "transfer" },
        { display: this.$t("transactions.transactionType.investing"), value: "investing" },
        { display: this.$t("transactions.transactionType.refund"), value: "capital_refund" },
        { display: this.$t("transactions.transactionType.mint"), value: "mint" },
        { display: this.$t("transactions.transactionType.burn"), value: "burn" },
        { display: this.$t("transactions.transactionType.token_block"), value: "token_block" },
        { display: this.$t("transactions.transactionType.token_unblock"), value: "token_unblock" },
        { display: this.$t("transactions.transactionType.capital_payment"), value: "capital_payment" },
        { display: this.$t("transactions.transactionType.deposit"), value: "deposit" }],
      type: null,
      date: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
      cards: [
        { value: 2164927, name: "Переводы", color: "#489EE1" },
        { value: 679608.75, name: "Инвестиции", color: "#E2A64D" },
        { value: 95020, name: "Товары", color: "#299667" },
        { value: 679382.75, name: "Биржа", color: "#9720F6" }
      ]
    };
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses",
      isLightTheme: "isLightTheme",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      defaultTokenId: "defaultToken/defaultTokenId",
      contacts: "marketplace/userContact",
      spot: "spot",
      language: "language"
    }),

    series() {
      return _.map(this.cards, "value");
    },

    options() {
      return {
        labels: _.map(this.cards, "name"),
        pie: {
          expandOnClick: false
        },
        colors: _.map(this.cards, "color"),
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(value) {
              return value.toLocaleString("ru", { style: "currency", currency: "RUB" }).replace(",00", "");
            }
          }
        },
        legend: {
          show: false
        }
      };
    },

    typeTitle() {
      let ret = _.find(this.typeArray, el => el.value === this.type);
      ret = ret?.value ?? "all";
      return this.$t(`transactions.titleType.${ret}`);
    },

    dateTitle() {
      let dateTitle = "all time";
      if (this.date) {
        dateTitle = this.format(this.date);
      }
      return dateTitle;
    },

    spending() {
      return {
        sum: this.currencySup.getTransactionsSum(this.transactions).sum,
        isPositive: this.currencySup.getTransactionsSum(this.transactions).isPositive
      };
    },

    course() {
      let currency = this.currencySup.getCurrency(this.id, this.defaultTokenMnemonic);
      // убрал .toFixed(2), если курс будет напрмиер 0,12005600 и внес правки в AuDecimalStatic
      return currency ? Number(currency) : 0;
    },

    empty() {
      return this.transactions.length === 0;
    },

    ourEth() {
      return _.find(this.arrEth, eth => !_.isNil(eth.verifiedAt))?.value;
    },

    tokenIcon() {
      return (this.correctToken?.logoMain ?? this.correctToken?.mainImage?.name) ?? "/svg/companies/s_logo.svg";
    },

    isDefaultToken() {
      return this.correctToken?.mnemonicEng == this.defaultTokenMnemonic;
    }
  },

  async mounted() {
    this.loading = true;
    const userId = this.$auth.user().id;
    const ownerAddress = this.arrEth[0].value;
    await this.getTransactions();
    await this.getToken();
    await this.getUserContact({ page: 1, itemsPerPage: 30 });
    const owner = await this.getAuOwnerByTokenId({ tokenId: this.id, ownerAddress: ownerAddress });
    await this.getSpot({ userId: userId, tokenId: this.id });
    this.cryptoBalance = _.get(owner, "[0].amount", "0");
    this.blocked = _.get(owner, "[0].amountBlocked", 0);
    this.spotBalance = _.get(this.spot, "[0].amount", "0");
    this.balance = BigNumber(this.cryptoBalance).plus(this.spotBalance).toString(10);
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getTransactionsPageOwnerFrom: "getTransactionsPage",
      getUserContact: "marketplace/getUserContact",
      getAuOwnerByTokenId: "getAuOwnerByTokenId",
      getSpot: "getSpot"
    }),

    async changeType(value) {
      this.type = value;
      this.transactions = [];
      await this.getTransactions();
    },

    async changeDate(value) {
      this.date = value;
      this.transactions = [];
      await this.getTransactions();
    },

    getFormatedValue(value) {
      return value.toLocaleString("ru", { style: "currency", currency: "RUB" }).replace(",00", "");
    },

    format(date) {
      const day1 = date[0].getDate();
      const month1 = date[0].getMonth() + 1;
      const year1 = date[0].getFullYear();

      const day2 = date[1].getDate();
      const month2 = date[1].getMonth() + 1;
      const year2 = date[1].getFullYear();

      if (year1 == year2 && month1 == month2 && day1 == day2) {
        return `${moment(date[0]).locale(this.language).format("L")}`;
      }

      if (year1 == year2 && month1 == month2 && day1 == 1 && (day2 == new Date(year2, month2, 0).getDate() || day2 == new Date().getDate())) {
        let month = moment(date[0]).locale(this.language).format("MMMM");
        return month[0].toUpperCase() + month.slice(1) + " " + year1;
      }

      if (year1 == year2 && month1 == 1 && day1 == 1 && month2 == 12 && day2 == 31) {
        return year1;
      }

      return `${moment(date[0]).locale(this.language).format("L")} - ${moment(date[1]).locale(this.language).format("L")}`;
    },

    async getToken() {
      let token = await this.axios.get(`/api/au_tokens/${this.$route.params.id}`);
      this.correctToken = token.data;
    },

    async getTransactions() {
      this.loadingLocal = true;
      let param = {
        pageId: this.page,
        itemsPerPage: 30,
        auTokenId: this.id,
        adr: this.ourEth
      };
      if (this.type !== "all") {
        param.methodName = this.type;
      }
      if (this.date) {
        param.dateFrom = moment(this.date[0]).clone().startOf("day").utc().format();
        param.dateTo = moment(this.date[1]).clone().endOf("day").utc().format();
      }
      let resp = await this.getTransactionsPageOwnerFrom(param);
      let data = resp.data;
      this.totalItems = resp.totalItems;
      this.showGetMore += data.length;

      data.forEach(transaction => {
        const time = moment(transaction.createdAt).format("DD.MM.YYYY");
        let records = this.transactions.find(el => el.date == time)?.transactions;
        if (records === undefined) {
          this.transactions.push({ "date": time, "sort": transaction.createdAt, "transactions": [] });
          records = this.transactions.at(-1).transactions;
        }
        this.arrEth.forEach((eth) => {
          if ((eth.value).toLowerCase() === (transaction.addressFrom).toLowerCase()) {
            this.addressEth = transaction.addressTo;
          }
          if ((eth.value).toLowerCase() === (transaction.addressTo).toLowerCase()) {
            this.addressEth = transaction.addressFrom;
          }
          if (transaction.addressFrom == 0) {
            this.addressEth = transaction.addressTo;
          }
        });
        records.push({
          icon: transaction.auToken?.logoMain ?? transaction.auToken?.mainImage?.name,
          name: this.addressEth.slice(0, 4) + "..." + this.addressEth.slice(-4),
          link: process.env.VUE_APP_SCAN_BASE_URL + "address/" + transaction.addressTo,
          type: transaction?.methodName,
          addressFrom: transaction.addressFrom,
          addressTo: transaction.addressTo,
          addressEth: this.addressEth,
          amount: transaction.amount,
          isConfirmed: transaction.isConfirmed,
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill: this.ourEth == transaction?.addressTo,
          decimals: transaction.auToken.decimals,
          mnemonic: transaction.auToken.mnemonicEng,
          createdAt: transaction.createdAt,
          hash: transaction.hash,
          projectId: _.get(transaction, "auCapital.id", null),
          projectName: _.get(transaction, "auCapital.tokenBase.nameEng", "")
        });
      });
      this.loadingLocal = false;
    },

    async getMore() {
      this.page++;
      await this.getTransactions();
    },

    goToScanner() {
      window.location.href = `${this.scanURL}token/${this.correctToken.createdAddress}`;
    },

    goToDaoTokens() {
      this.$router.push({ name: "dao tokens", params: { id: this.id } });
    },

    copyToClipboard() {
      navigator.clipboard.writeText(this.correctToken.createdAddress)
        .then(() => {
          this.$toast.success(this.$t("tokeninfo.copySuccess"));
        })
        .catch(() => {
          this.$toast.error(this.$t("tokeninfo.copyError"));
        });
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 100%;
  padding: 20px 22px 22px 20px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 40px;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 25px;

    &-date {
      font-size: 14px;
    }

    &-line {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-70);
      margin-bottom: 20px;
      padding-bottom: 10px;
    }

    &-month {
      color: #279063
    }
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.period {
  margin: 15px 0px;
  font-size: 11px;
}

.transactions-header {
  border-bottom: 1px solid var(--color-70);
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &-group {
    margin-top: 12px;
  }

  &-footer {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    border-top: 1px dashed var(--color-70);
  }

  &-info {
    display: flex;
    border-bottom: 1px solid var(--color-70);

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }

    &__chart {
      display: flex;
      align-items: center;

      @media screen and (max-width: 576px) {
        justify-content: space-between;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-right: -15px;
    }

    &__cards {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      padding: 25px 0px;
    }
  }
}

.btn_metamask {
  display: flex;
  justify-content: center;
  align-items: center;
}
.metamask {
  background: url(../assets/img/metamask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  margin-right: 10px;
}
.count-text{
  font-size: 12px;
  color: var(--color-60);
}

.token-header {
  align-items: center;
  margin-bottom: 25px;

  .logo-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .logo {
    height: 25px;
  }
}

.balance {
  display: flex;
  flex-direction: column;
  line-height: 0.9;
  gap: 6px;

  &__value {
    width: 600px;
    font-size: 24px;
  }

  &__string {
    font-size: 12px;
    color: var(--color-50)
  }
}

.token-balance {
  font-size: 14px;
  color: var(--color-30);
}

.token-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 655px) {
    flex-direction: column;
    gap: 10px;
  }

  &__block {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 344px) {
    flex-direction: column;
  }
  }

  &__panel {
    display: flex;
    padding: 30px 0px;
    flex-direction: column;
    gap: 30px;
    border-bottom: 1px dashed var(--color-70);
  }

  &__address {
    display: flex;
    gap: 10px;
    align-items: center;

    font-size: 14px;
    color: var(--color-30);
    text-decoration: underline;

    cursor: pointer;
  }
}

.filter-panel {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px 40px 0px;
}

.action-button{
  cursor: pointer;
}
.pucket-button {
  white-space: nowrap;
  color: #ffffff;
}
.withdraw-balance {
  font-size: 10px;
  color: var(--color-60);
}
.mr {
  margin-right: 6px;
}
.mb {
  margin-bottom: 4px;
}

</style>
