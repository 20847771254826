<template>
  <div
    v-if="$route.name === 'briefcase'"
    class="briefcase"
  >
    <AuBreadcrumb
      parent-name="Dashboard"
    />
    <div class="briefcase-button">
      <AuButton
        type="primary"
        center
        width="200px"
        @click="goToTopUp"
      >
        {{ $t("briefcase.buy") }}
      </AuButton>

      <AuButton
        bordered
        center
        width="200px"
        @click="goToWithdraw"
      >
        {{ $t("briefcase.withdraw") }}
      </AuButton>
    </div>

    <AuTabView resizable>
      <AuTabPanel :title="$t('briefcase.cryptoTabLabel')">
        <AuTable
          class="briefcase-table briefcase-table-hiddable"
          :columns="columnsCrypto"
          :items="itemsCrypto"
          :action-column="actionColumn"
          action-type="contextMenu"
          :loading="loadingCrypto"
          :property-style-function="cryptoPropertyStyleFunction"
          @send="send"
          @topUp="goToTopUp"
          @toWithdraw="goToWithdraw"
        >
          <template #company="{ value }">
            <div
              class="link link-container"
              @click="toLink(value.link)"
            >
              <img
                :src="value.icon"
                class="icon-with-bg"
                alt=""
              >
              <div>
                <span class="link-text">
                  {{ value.name }}
                </span>
              </div>
            </div>
          </template>
          <template #tokensCount="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimals"
              :show-addition="false"
            />
          </template>
          <template #tokenSum="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimals"
              :show-addition="false"
            />
          </template>
          <template #tokenBlocked="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimals"
              :show-addition="false"
            />
          </template>
        </AuTable>

        <div class="briefcase-accordion">
          <div v-if="itemsCrypto.length != 0">
            <AuAccordion
              v-for="(owner, index) in itemsCrypto"
              :key="owner"
              :last-child="(index == itemsCrypto.length - 1)"
            >
              <template #title>
                <span class="company-header"><img
                  class="icon-with-bg"
                  :src="owner.company.icon"
                  :alt="owner.company.name"
                > {{ owner.company.name }}</span>
              </template>
              <template #content>
                <div class="company-info container-fluid">
                  <div
                    v-for="(column, cindex) in columnsCryptoAccordion"
                    :key="cindex"
                    class="row"
                  >
                    <div class="col company-info-title">
                      <span>
                        {{ column.name }}
                      </span>
                    </div>
                    <div
                      class="col"
                    >
                      <AuDecimalStatic
                        v-if="column.decimal"
                        :value="owner[column.property].value"
                        :decimal="owner[column.property].decimals"
                        :show-addition="false"
                      />
                      <span v-else>{{ getPropertyValue(owner, column.property) }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col buttons">
                      <AuButton
                        v-if="owner.company?.isDefault"
                        width="100%"
                        center
                        @click="topUp(itemIndex)"
                      >
                        {{ $t("briefcase.changeAction") }}
                      </AuButton>
                      <AuButton
                        v-if="owner.company?.isDefault"
                        width="100%"
                        center
                        @click="$emit('toWithdraw', itemIndex)"
                      >
                        {{ $t("briefcase.bringOutAction") }}
                      </AuButton>
                      <AuButton
                        width="100%"
                        center
                        @click="send(index)"
                      >
                        {{ $t("briefcase.sendAction") }}
                      </AuButton>
                    </div>
                  </div>
                </div>
              </template>
            </AuAccordion>
          </div>

          <ProgressLoader
            v-if="loadingCrypto"
            mode="indeterminate"
            color="#5AC396"
            size="150px"
          />

          <span v-else-if="itemsCrypto.length == 0">
            No assets yet
          </span>
        </div>


        <AuPagination
          v-if="ownerTotalItems > itemsPerPage"
          :hide="loadingCrypto || !itemsCrypto.length"
          :total-items="ownerTotalItems"
          @pageChanged="pageCryptoChanged"
        />
      </AuTabPanel>

      <AuTabPanel
        :title="$t('briefcase.ordersTabLabel')"
        :hidden="$auth.user().type == 'issuer'"
      >
        <AuTable
          class="briefcase-table briefcase-table-hiddable"
          :columns="columnsOrders"
          :items="itemsOrders"
          :loading="orderLoading"
        >
          <template #project="{ value }">
            <div
              class="link link-container"
              @click="toLinkProject(value.id)"
            >
              <div>
                <span class="link-text">
                  {{ value.name }}
                </span>
              </div>
            </div>
          </template>
          <template #token="{ value }">
            <div
              class="link"
              @click="toLink(value.id)"
            >
              <span class="link-text">
                {{ value.mnemonic }}
              </span>
            </div>
          </template>
          <template #sum="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimals"
              :show-addition="false"
            />
          </template>
          <template #maxCap="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimals"
              :show-addition="false"
            />
          </template>
        </AuTable>

        <div class="briefcase-accordion">
          <div v-if="itemsOrders != 0">
            <AuAccordion
              v-for="(order, index) in itemsOrders"
              :key="order"
              :last-child="(index == itemsOrders.length - 1)"
            >
              <template #title>
                <div
                  class="link link-container"
                  @click="toLinkProject(order.project.id)"
                >
                  <div>
                    <span class="link-text">
                      {{ order.project.name }}
                    </span>
                  </div>
                </div>
              </template>
              <template #content>
                <div class="company-info container-fluid">
                  <div
                    v-for="(column, cindex) in columnsOrdersAccordion"
                    :key="cindex"
                    class="row"
                  >
                    <div class="col company-info-title">
                      <span>
                        {{ column.name }}
                      </span>
                    </div>
                    <div class="col">
                      <div v-if="column.name == 'Mnemonic'">
                        <div
                          class="link"
                          @click="toLink(order.token.id)"
                        >
                          <span class="link-text">
                            {{ order.token.mnemonic }}
                          </span>
                        </div>
                      </div>

                      <AuDecimalStatic
                        v-if="column.decimal"
                        :value="order[column.property].value"
                        :decimal="order[column.property].decimals"
                        :show-addition="false"
                      />

                      <div v-if="column.name == 'End date'">
                        <div>
                          {{ order.dateEnd }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </AuAccordion>
          </div>

          <ProgressLoader
            v-if="dataLoading"
            mode="indeterminate"
            color="#5AC396"
            size="150px"
          />

          <span v-else-if="itemsOrders.length == 0">
            No orders yet
          </span>
        </div>

        <AuPagination
          v-if="totalWithdraws > itemsPerPage"
          :hide="dataLoading || !itemsOrders.length"
          :total-items="ordersTotalItems"
          @pageChanged="pageOrdersChanged"
        />
      </AuTabPanel>

      <AuTabPanel
        :title="$t('briefcase.votesTabLabel')"
        :hidden="$auth.user().type == 'issuer'"
      >
        <AuTable
          class="briefcase-table briefcase-table-hiddable"
          :columns="columnsVotes"
          :items="itemsVotes"
          :loading="votesLoading || dataLoading"
        >
          <template #project="{ value }">
            <div
              class="link link-container"
              @click="toLinkProject(value.id)"
            >
              <div>
                <span class="link-text">
                  {{ value.name }}
                </span>
              </div>
            </div>
          </template>
          <template #token="{ value }">
            <div
              class="link"
              @click="toLink(value.id)"
            >
              <span class="link-text">
                {{ value.mnemonic }}
              </span>
            </div>
          </template>
          <template #sum="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimals"
              :show-addition="false"
            />
          </template>
          <template #expectedProfit="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimal"
              :indent="value.indent"
              :show-addition="false"
            />
          </template>
        </AuTable>

        <div class="briefcase-accordion">
          <div v-if="itemsVotes != 0">
            <AuAccordion
              v-for="(order, index) in itemsVotes"
              :key="order"
              :last-child="(index == itemsVotes.length - 1)"
            >
              <template #title>
                <div
                  class="link link-container"
                  @click="toLinkProject(order.project.id)"
                >
                  <div>
                    <span class="link-text">
                      {{ order.project.name }}
                    </span>
                  </div>
                </div>
              </template>
              <template #content>
                <div class="company-info container-fluid">
                  <div
                    v-for="(column, cindex) in columnsVotesAccordion"
                    :key="cindex"
                    class="row"
                  >
                    <div class="col company-info-title">
                      <span>
                        {{ column.name }}
                      </span>
                    </div>
                    <div class="col">
                      <div v-if="column.name == 'Mnemonic'">
                        <div
                          class="link"
                          @click="toLink(order.token.id)"
                        >
                          <span class="link-text">
                            {{ order.token.mnemonic }}
                          </span>
                        </div>
                      </div>

                      <div v-if="column.name == 'Expected profit'">
                        <AuDecimalStatic
                          :value="order.expectedProfit.value"
                          :decimal="order.expectedProfit.decimal"
                          :indent="order.expectedProfit.indent"
                          :show-addition="false"
                        />
                      </div>

                      <AuDecimalStatic
                        v-if="column.decimal"
                        :value="order[column.property].value"
                        :decimal="order[column.property].decimals"
                        :show-addition="false"
                      />

                      <div v-if="column.name == 'End date'">
                        <div>
                          {{ order.dateEnd }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </AuAccordion>
          </div>

          <ProgressLoader
            v-if="dataLoading"
            mode="indeterminate"
            color="#5AC396"
            size="150px"
          />

          <span v-else-if="itemsVotes.length == 0">
            No orders yet
          </span>
        </div>

        <AuPagination
          v-if="totalWithdraws > itemsPerPage"
          :hide="dataLoading || !itemsVotes.length"
          :total-items="votesTotalItems"
          @pageChanged="pageVotesChanged"
        />
      </AuTabPanel>

      <AuTabPanel :title="$t('briefcase.withdrawTabLabel')">
        <AuTable
          class="briefcase-table briefcase-table-hiddable"
          :columns="columnsWithdraw"
          :items="itemsWithdraw"
          :loading="dataLoading"
        >
          <template #amount="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimals"
              :show-addition="false"
            />
          </template>
        </AuTable>

        <div class="briefcase-accordion">
          <div v-if="itemsWithdraw != 0">
            <AuAccordion
              v-for="(order, index) in itemsWithdraw"
              :key="order"
              :last-child="(index == itemsWithdraw.length - 1)"
            >
              <template #title>
                <span class="company-header">{{ order.createdAt }}</span>
              </template>
              <template #content>
                <div class="company-info container-fluid">
                  <div
                    v-for="(column, cindex) in columnsWithdraw"
                    :key="cindex"
                    class="row"
                  >
                    <div class="col company-info-title">
                      <span>
                        {{ column.name }}
                      </span>
                    </div>
                    <div class="col">
                      <AuDecimalStatic
                        v-if="column.name == 'Sum'"
                        :value="order.amount.value"
                        :decimal="order.amount.decimals"
                        :show-addition="false"
                      />
                      <span v-else>{{ getPropertyValue(order, column.property) }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </AuAccordion>
          </div>

          <ProgressLoader
            v-if="dataLoading"
            mode="indeterminate"
            color="#5AC396"
            size="150px"
          />

          <span v-else-if="itemsOrders.length == 0">
            No orders yet
          </span>
        </div>

        <AuPagination
          v-if="totalWithdraws > itemsPerPage"
          :hide="dataLoading || !itemsWithdraw.length"
          :total-items="totalWithdraws"
          @pageChanged="pageWithdrawChanged"
        />
      </AuTabPanel>
    </AuTabView>
  </div>
  <RouterView v-else />
</template>

<script>
import _ from "lodash";
import AuTabPanel from "@/components/extended/tabs/AuTabPanel";
import AuTabView from "@/components/extended/tabs/AuTabView";
import CurrencySupport from "@/help/currencySupport";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { AuTabPanel, AuTabView, ProgressLoader },
  data() {
    return {
      actionColumn: { label: "Операции", icon: "more" },
      currencySup: new CurrencySupport(),
      pageCrypto: 1,
      pageWithdraw: 1,
      itemsPerPage: 30,
      dataOwners: null,
      ownerTotalItems: 0,
      defaultToken: null,
      loadingCrypto: false,
      pageOrders: 1,
      pageVotes: 1,
      dataOrders: null,
      dataVotes: null,
      ordersTotalItems: null,
      votesTotalItems: null,
      orderLoading: true,
      votesLoading: true
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      tokensUtility: "tokensUtility",
      tokensFiat: "tokensFiat",
      exchangeBalances: "exchangeBalances",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      defaultAutMnemonic: "defaultToken/defaultAutMnemonic",
      defaultTokenId: "defaultToken/defaultTokenId",
      withdraws: "withdrawal/withdraws",
      totalWithdraws: "withdrawal/totalWithdraws",
      dataLoading: "withdrawal/dataLoading",
      arrEth: "ethAddresses"
    }),

    columnsCrypto() {
      return [{ name: this.$t("briefcase.firstColumn"), align: "start", slot: "company", property: "company" },
        ...this.columnsCryptoAccordion];
    },

    columnsCryptoAccordion() {
      return [{ name: this.$t("briefcase.secondColumn"), slot: "tokensCount", property: "tokensCount", decimal: true },
        { name: this.$t("briefcase.thirdColumn"), property: "tokenMnemonic" },
        { name: `${this.$t("briefcase.fourthColumn")} ${this.defaultTokenMnemonic}`, property: "tokenCost" },
        { name: `${this.$t("briefcase.sumColumn")} ${this.defaultTokenMnemonic}`, slot: "tokenSum", property: "tokenSum", decimal: true },
        { name: this.$t("briefcase.fifthColumn"), slot: "tokenBlocked", property: "tokenBlocked", decimal: true }];
    },

    columnsOrders() {
      return [{ name: this.$t("briefcase.projectName"), align: "start", slot: "project", property: "project" },
        ...this.columnsOrdersAccordion];
    },

    columnsOrdersAccordion() {
      return [{ name: "Mnemonic", slot: "token", property: "token" },
        { name: `${this.$t("briefcase.invested")} ${this.defaultTokenMnemonic}`, align: "center", slot: "sum", property: "sum", decimal: true },
        { name: `${this.$t("briefcase.hardCap")} ${this.defaultTokenMnemonic}`, align: "center", slot: "maxCap", property: "maxCap", decimal: true },
        { name: this.$t("briefcase.endDate"), align: "center", property: "dateEnd" }];
    },

    columnsVotes() {
      return [
        { name: this.$t("briefcase.projectName"), align: "start", slot: "project", property: "project" },
        ...this.columnsVotesAccordion];
    },

    columnsVotesAccordion() {
      return [
        { name: "Mnemonic", slot: "token", property: "tokenMnemonic" },
        { name: `${this.$t("briefcase.vote")} ${this.defaultAutMnemonic}`, align: "center", slot: "sum", property: "sum", decimal: true },
        { name: `${this.$t("briefcase.profit")}`, align: "center", slot: "expectedProfit", property: "expectedProfit" },
        { name: this.$t("briefcase.endDate"), align: "center", property: "dateEnd" }
      ];
    },

    columnsWithdraw() {
      return [{ name: this.$t("briefcase.dateColumn"), align: "start", property: "createdAt" },
        { name: this.$t("briefcase.tokenColumn"), align: "center", property: "auToken" },
        { name: this.$t("briefcase.sum2Column"), align: "center", slot: "amount", property: "amount" },
        { name: this.$t("briefcase.statusColumn"), align: "center", property: "status" }];
    },

    itemsCrypto() {
      return _.map(this.dataOwners, auOwner => {
        const price = this.currencySup.getCurrency(auOwner.auToken.id, this.defaultTokenMnemonic);

        return {
          company: {
            icon: (auOwner.auToken?.logoMain ?? auOwner.auToken?.mainImage?.name) ?? "/svg/companies/s_logo.svg",
            link: auOwner.auToken.id,
            name: auOwner.auToken.nameEng,
            isDefault: auOwner.auToken.mnemonicEng == this.defaultTokenMnemonic
          },
          tokensCount: {
            value: auOwner.amount,
            decimals: auOwner.auToken.decimals
          },
          tokenMnemonic: auOwner.auToken.mnemonicEng,
          tokenCost: price == "0" ? "-" : price,
          tokenSum: {
            value: price == "0" ? "-" : auOwner.amount,
            decimals: auOwner.auToken.decimals
          },
          tokenBlocked: {
            value: auOwner.amountBlocked == "0" ? "-" : auOwner.amountBlocked,
            decimals: auOwner.auToken.decimals
          }
        };
      });
    },

    itemsOrders() {
      return _.map(this.dataOrders, item => ({
        project: {
          name: item?.tokenBase?.nameEng,
          id: item?.id
        },
        token: {
          mnemonic: item?.tokenBase?.mnemonicEng,
          id: item?.tokenBase.id
        },
        sum: {
          value: item?.sum,
          decimals: item?.tokenQuote?.decimals
        },
        maxCap: {
          value: item?.maxCap,
          decimals: item?.tokenQuote?.decimals
        },
        dateEnd: moment(item?.dateEnd).format("DD MMM YYYY")
      }));
    },

    itemsVotes() {
      return _.map(this.dataVotes, item => ({
        project: {
          name: item?.tokenBase?.nameEng,
          id: item?.id
        },
        token: {
          mnemonic: item?.tokenBase?.mnemonicEng,
          id: item?.tokenBase.id
        },
        sum: {
          value: item?.sum,
          decimals: item?.tokenQuote?.decimals
        },
        expectedProfit: {
          value: item?.expectedProfit,
          decimal: item?.tokenQuote.decimals,
          indent: item?.tokenQuote.indent
        },
        dateEnd: moment(item?.dateEnd).format("DD MMM YYYY")
      }));
    },

    itemsWithdraw() {
      return _.map(this.withdraws, item => ({
        createdAt: moment(item.createdAt).format("DD MMM YYYY"),
        auToken: item?.token?.mnemonicEng,
        amount: {
          value: item.value,
          decimals: item?.token?.decimals
        },
        status: this.getStatus(item.isConfirmed) ?? "-"
      }));
    }
  },

  async updated() {
    if (this.$route.name === "briefcase") {
      await this.getWithdrawPage();
      await this.getDataPage();
    }
  },

  async mounted() {
    this.defaultToken = _.find(this.tokens, token => token.id == this.defaultTokenId);
    await this.getDataPage();
    await this.getOrdersPage();
    await this.getVotesPage();
    await this.getWithdrawPage();
  },

  methods: {
    ...mapActions({
      getOwnerPage: "getOwnerPage",
      getAuOwnerByTokenId: "getAuOwnerByTokenId",
      getWithdrawalRequests: "withdrawal/getWithdrawalRequests",
      getInvestmentsAuCapitals: "capitals/getInvestmentsAuCapitals"
    }),

    cryptoPropertyStyleFunction(property, item) {
      if (item?.tokensCount?.value == 0) {
        return {
          "color": "var(--color-60)",
          "--opacity": "50%"
        };
      }
    },

    getPropertyValue(data, property) {
      return _.get(data, [property]);
    },

    toLink(link) {
      this.$router.push({ name: "token info", params: { id: link } });
    },
    toLinkProject(link) {
      this.$router.push({ name: "project card", params: { id: link } });
    },

    send(index) {
      this.$router.push({ name: "send token", params: { id: this.dataOwners[index].auToken.id } });
    },

    goToTopUp() {
      this.$router.push({ name: "buy form" });
    },

    goToWithdraw() {
      this.$router.push({ name: "withdraw" });
    },

    getStatus(status) {
      return status ? this.$t("briefcase.confirmed") : this.$t("briefcase.notConfirmed");
    },

    async getNonAssetBalances(owners) {
      const nonAsset = [];
      const unique = new Set();
      _.forEach([...this.tokensFiat, ...this.tokensUtility], item => unique.add(item));
      for (const token of unique.values()) {
        const resp = await this.getAuOwnerByTokenId({ tokenId: token.id, ownerAddress: this.arrEth[0].value });
        if (resp.length === 0) {
          nonAsset.push({ auToken: token, amount: 0, amountBlocked: 0 });
        }
      }
      return [...nonAsset, ...owners];
    },

    async getDataPage() {
      this.loadingCrypto = true;
      if (!this.arrEth[0]?.verifiedAt) {
        this.loadingCrypto = false;
        return;
      }
      try {
        const resp = await this.getOwnerPage({
          page: this.pageCrypto,
          itemsPerPage: this.itemsPerPage
        });
        const owners = this.pageCrypto === 1 ? await this.getNonAssetBalances(resp.data) : resp.data;
        const first = _.filter(owners, el => el.auToken.type === "utility" || el.auToken.type === "fiat");
        const other = _.reject(owners, el => el.auToken.type === "utility" || el.auToken.type === "fiat");
        this.dataOwners = [...first, ...other];
        this.ownerTotalItems = resp.totalItems;
      }
      catch (e) {
        this.$toast.error(this.$t("briefcase.error"));
      }
      this.loadingCrypto = false;
    },

    async getWithdrawPage() {
      await this.getWithdrawalRequests({
        page: this.pageWithdraw,
        itemsPerPage: this.itemsPerPage,
        type: "token_withdrawal"
      });
    },

    async getOrdersPage() {
      this.orderLoading = true;
      try {
        let resp = await this.getInvestmentsAuCapitals({
          pageId: this.pageOrders,
          itemsPerPage: this.itemsPerPage,
          projectType: "invest",
          status: "open"
        });

        this.dataOrders = resp.data;
        this.ordersTotalItems = resp.totalItems;
      }
      catch (e) {
        console.log("error", e);
      }
      finally {
        this.orderLoading = false;
      }
    },
    async getVotesPage() {
      this.votesLoading = true;
      try {
        let resp = await this.getInvestmentsAuCapitals({
          pageId: this.pageVotes,
          itemsPerPage: this.itemsPerPage,
          projectType: "voting",
          status: "open"
        });

        this.dataVotes = resp.data;
        this.votesTotalItems = resp.totalItems;
      }
      catch (e) {
        console.log("error", e);
      }
      finally {
        this.votesLoading = false;
      }
    },

    async pageCryptoChanged(actualPage) {
      this.pageCrypto = actualPage;
      await this.getDataPage();
    },

    async pageOrdersChanged(actualPage) {
      this.pageOrders = actualPage;
      await this.getOrdersPage();
    },

    async pageVotesChanged(actualPage) {
      this.pageVotes = actualPage;
      await this.getVotesPage();
    },

    async pageWithdrawChanged(actualPage) {
      this.pageWithdraw = actualPage;
      await this.getWithdrawPage();
    }
  }
};
</script>

<style lang="scss">
.briefcase {
  .lv-progressbar {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }
}
</style>


<style lang="scss" scoped>
.briefcase-main {
  padding: 20px;
}
.briefcase-button {
  display: flex;
  gap: 20px;
}

.briefcase {
  &-info {
    padding: 11px 20px 0 18px;

    .title {
      font-weight: 600;
      font-size: 24px;
      color: var(--text-color);
      margin-bottom: 30px;
    }
  }

  &-table {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-70);

    &-hiddable {
      @media screen and (max-width:590px) {
        display: none;
      }
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:590px) {
      display: block;
    }
  }
}

.spot-balance {
  color: var(--color-50);
  font-weight: 500;
  font-size: 12px;
}

.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }

  &-info {
    color: var(--text-color);
    display: flex;
    padding-right: 0;
    padding-left: 0;
    flex-direction: column;
    gap: 20px;

    &-title {
      color: var(--color-60)
    }

    .row {
      font-weight: 450;
      font-size: 12px;

      .col:last-child {
        text-align: end;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

}

.icon-with-bg {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  opacity: var(--opacity);
}

.link-container {
  display: flex;
  align-items: center;
}

.link {
  &:hover {
    cursor: pointer;
  }
  .link-text {
    line-height: 1.1;
    &:hover {
      border-bottom: 1px dashed var(--color-00);
    }
  }
}
</style>
