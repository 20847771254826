<template>
  <div
    class="no_data"
  >
    <span v-if="text == 'noData'">{{ $t('briefcase.ordersNoData') }}</span>
    <span v-if="text == 'noTrx'">{{ $t('briefcase.ordersNoTrx') }}</span>
  </div>
</template>

<script>
export default {
  name: "AuNoData",
  props: {
    text: {
      type: String,
      default: "noData"
    }
  }
};
</script>

<style scoped lang="scss">
.no_data {
  padding-top: 24px;
  display: flex;
  justify-content: center;
  color: var(--color-50);
}
</style>

