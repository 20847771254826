<template>
  <div>
    <AuBreadcrumb
      parent-name="Dashboard"
    />
    <LastTransactionsTable />
  </div>
</template>

<script>
import LastTransactionsTable from "@/components/lastTransactions/LastTransactionsTable.vue";

export default {
  components: {
    LastTransactionsTable
  }
};
</script>